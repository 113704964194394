.edit-user-form {
	.title {
		color: #458d35;
		margin: unset;
	}
}
@media (max-width:767px) {
	.edit-user-form {
		.row {
			.col {
				flex: unset;
			}
		}
	}
}

