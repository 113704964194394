nav {
	.logo {
		color: primary;
	}
	.navbar-inner {
		.navbar-collapse {
			.navbar-nav {
				a {
					display: flex;
					align-items: center;
					padding-right: 12px;
					&:hover {
						color: primary;
					}
				}
				.active {
					color: primary;
				}
			}
		}
	}
}
.navbar-inner {
	.navbar-collapse {
		.navbar-nav {
			padding: 0px 11px;
		}
	}
}
.nav-item.dropdown {
	&:hover {
		.dropdown-menu {
			display: block;
		}
	}
}
@media (max-width:991px) {
	nav {
		.navbar-inner {
			.navbar-collapse {
				.navbar-nav {
					a {
						padding-left: 8px;
					}
				}
			}
		}
	}
}
@media (max-width:469px) {
	nav {
		.hmaburger-menu-btn {
			order: 2;
		}
		.dropdown-option {
			order: 3;
			margin-top: 15px;
		}
	}
}
