.tooltip-modal {
	tbody {
		>tr {
			>td {
				min-width: 90px;
				border: 2px solid #c0bebe;
			}
		}
	}
	.table-primary {
		background-color: #009FE3;
	}
	.table-success {
		background-color: #d8e6a8;
	}
	.table-danger {
		background-color: #ff9899;
	}
	.table-warning {
		background-color: #fdd4a6;
	}
}
.clinical-risk {
	tbody {
		>tr {
			>td {
				width: 50%;
				font-size: 1.25rem !important;
				padding: 1.5em;
			}
		}
	}
}
