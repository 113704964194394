/* make the customizations */

$body-bg : #f5f5f5;

$primary : #46479b;
$secondary : #f82d4a;
$success: green;
$info: #21c3db;
$warning: #ff9402;
$danger: #b8165a;
$grey:grey;
$vacant:#198754;
$occupied:#d9534f;
// Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$navbar-light-active-color : $primary;
/*
// Create your own map
$custom-colors: (
	"primary" : $primary,
	"secondary" : $secondary,
);
// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);
*/
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/utilities";

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.form-control {
	background-color: #fff;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1;
}

/* .card-header {
	background-color: #dee2e6;
} */
.text-medium-emphasis{
	--cui-text-opacity: 1;
	color: rgba(44, 56, 74, .681) !important;
}
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
}
.notification .dropdown-item:hover,
.dropdown-item:focus{
	background-color:inherit;
}
body.fixed-header .page {
	padding-top: 4.5rem;
}

@media (min-width: 1600px) {
	body.aside-opened .page {
		margin-right: 22rem;
	}
}

.list-inline-dots .list-inline-item+.list-inline-item:before {
	content: "\B7   ";
	margin-left: -2px;
	margin-right: 3px;
}

.page-main {
	flex: 1 1 auto;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
	cursor: not-allowed;
	pointer-events: auto;
}

/* .table-chart table {
	border-radius: 0.2rem;
	width: 100%;
	padding-bottom: 1rem;
	margin-bottom: 0;
} */

.table-chart th:first-child,
.table-chart td:first-child {
	position: sticky;
	left: 0;
}
.io-chart th:last-child,
.io-chart td:last-child {
	position: sticky;
	right: 0;
}

.table-chart th, .table-chart td {
	white-space: nowrap;
}
.medicine-table th,.medicine-table td {
	white-space: nowrap;
}
.insv-table tr:first-child,
.insv-table tr:nth-child(2),
.insv-table td:first-child {
	position: sticky;
	left: 0;
}
.footer {
	background: #fff;
	border-top: 1px solid rgba(0, 40, 100, 0.12);
	font-size: 0.875rem;
	padding: 1.25rem 0;
	color: #9aa0ac;

	a {
		text-decoration: none;
		background-color: transparent;
	}

	a:hover {
		text-decoration: underline;
	}

	a:not([href]) {
		text-decoration: none;
	}

	a:not([href]):hover {
		text-decoration: none;
	}

	a:not(.btn) {
		color: #6e7687;
	}
}

@media print {
	.footer {
		display: none;
	}
}