#kanban {
	white-space: nowrap;
	.list {
		border-radius: 8px;
		margin: 5px;
		background-color: rgba(192, 192, 192, 0.4);
		display: inline-block;
		vertical-align: top;
		white-space: normal;
		width: 32%;
	}
	.list-title {
		font-size: 16px;
		padding: 10px;
		margin-bottom: -10px;
		font-weight: bold;
		cursor: pointer;
	}
	.sortable-cards {
		min-height: 380px;
	}
	.card {
		position: relative;
		background-color: white;
		box-sizing: border-box;
		padding: 0px 5px;
		margin: 0px 5px;
		cursor: pointer;
	}
	.card-assignee {
		opacity: 0.6;
	}
	.card-priority {
		position: absolute;
		top: 10px;
		bottom: 10px;
		left: 5px;
		width: 5px;
		border-radius: 2px;
		background: #86c285;
	}
}
