.videoRef {
    width: 100%;
    height: 300px;
}
.screen-open {
    height: auto;
    overflow: auto;
    max-height: calc(100vh - 340px);
}
video{
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
}