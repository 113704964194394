.dashboard_bed {
	min-height: 250px;
	border: 3px solid !important;
}
.bg-vacant {
	background-color: #198754;
}
.bg-occupied {
	background-color: #d9534f;
}
.btn-occupied{
	background-color: #198754;
	border-color:  #198754;
	color: white;
}
.btn-vacant{
	background-color: #d9534f;
	border-color:  #d9534f;
	color: white;
}
.text-vacant {
	color: #198754;
}
.text-occupied {
	color: #d9534f;
}
.bg-dark-red {
	background-color: #ac2925 !important;
}
.patient-detail {
	&:hover {
		background-color: #ac2925 !important;
		border-color: #a91e19 !important;
		cursor: pointer;
	}
}
.patient-detail-disabled {
	background-color: #ac2925 !important;
	border-color: #a91e19 !important;
	cursor: pointer;
}
