@import '../../App.scss';

.main-content {
	padding-top: 100px;
	padding-bottom: 100px;
}

.stat-card {
	min-height: 144px;
	background: #fff;
	padding: 25px;
	margin-bottom: 5px;
	border-radius: 5px;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	transition: all 0.2s;
	;

	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
	}

	&__icon {
		&-circle {
			height: 60px;
			width: 60px;
			border-radius: 60px;
			background: rgba($primary, 0.2);
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				font-size: 30px;
				color: $primary;
			}
		}

		&.stat-card__icon--success {
			.stat-card__icon-circle {
				background: rgba($success, 0.2);

				svg {
					color: $success;
				}
			}
		}

		&.stat-card__icon--danger {
			.stat-card__icon-circle {
				background: rgba($danger, 0.2);

				svg {
					color: $danger;
				}
			}
		}

		&.stat-card__icon--warning {
			.stat-card__icon-circle {
				background: rgba($warning, 0.2);

				svg {
					color: $warning;
				}
			}
		}

		&.stat-card__icon--primary {
			.stat-card__icon-circle {
				background: rgba($primary, 0.2);

				svg {
					color: $primary;
				}
			}
		}
	}
}

.colorbox {
	width: 30px;
	height: 30px;
	border-radius: 5px;
}